import React, { useState } from "react"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    height: 100%;
`
const Note = styled.div`
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    font-size: 70%;
`
const NoteFooter = styled.div`
    font-size: 70%;
    margin: 15px 0;
    padding: 10px;
    width: 100%;
    background: #323232;
    display: table-cell;
    vertical-align: bottom;
`
const Navigation = styled.div`
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;

    text-align: right;
`
const NavButton = styled.div`
    display: inline-block;
    opacity: 0.5;

    &:hover {
        cursor: pointer;
        opacity: 1;
    }

    &::selection {
        background: transparent;
        cursor: default;
    }
`

interface IProps {
    notes: {
        id: number;
        notes: string;
        proof: string;
        created_at: string;
    }[];
}

const AddressNotes = (props: IProps) => {
    const [commentIndex, setCommentIndex] = useState<number>(0);

    const { notes } = props;

    if(!notes) {
        return(<></>)
    }

    return(
        <Container>
            <Note>
                {notes[commentIndex].notes}
                {/* <NoteFooter>
                    Posted: {notes[commentIndex].created_at.split("T")[0]} <br />
                    Proof: 
                </NoteFooter> */}
            </Note>
            {notes.length > 1 && 
                <Navigation>
                    <NavButton onClick={() => setCommentIndex(commentIndex - 1 > 0 ? commentIndex - 1 : 0)}>[&#8592;]</NavButton> 
                    <NavButton onClick={() => setCommentIndex(commentIndex + 1 <= notes.length-1 ? commentIndex + 1 : notes.length-1)}>[&#8594;]</NavButton>
                </Navigation>
            }
        </Container>
    )
}

export default AddressNotes;